<template>
  <div>
    <AppHeader title="账单详情"/>
    <Panel class="tabs" :title="false">
      <div slot="content" v-infinite-scroll="loadPayLogs"
           infinite-scroll-disabled="loading"
           infinite-scroll-distance="10">
        <div class="line" v-for="(payLog,idx) of payLogs">
          <div class="flex-dir-row">
            <div class="flex-item-auto">
              <div>内容：{{ payLog.remark }}</div>
              <div>时间：{{ formatDate(payLog.createAt)}}</div>
              <div style="display: flex;align-items: center">备注：<span v-if="payLog.fileId" @click="preview(toImgUrl(payLog.fileId))" style="color: #3b5998">查看图片</span> {{ payLog.extRemark }}</div>
            </div>
            <div>金额：{{ payLog.amount }}</div>
          </div>
        </div>
        <div class="line text-center" v-if="payLogs.length===0">暂无记录</div>
      </div>
    </Panel>

    <mt-popup
        v-model="previewPopShow"
        position="right" popup-transition="popup-fade" class="preview-popup">
      <img :src="previewUrl">
    </mt-popup>
  </div>
</template>

<style lang="scss" scoped>
.preview-popup {
  width: 60%;
  left: 20%;
}
</style>
<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {InfiniteScroll} from 'mint-ui';

export default {
  components: {
    AppHeader,
    Panel,
    InfiniteScroll
  },
  data() {
    return {
      page: 1,
      payLogs: [],
      loading: false,
      noMore: false,
      previewPopShow: false,
      previewUrl: ''
    }
  },
  methods: {
    toImgUrl(fid) {
      return `${this.imgUrl}/${fid}`
    },
    preview(imgUrl) {
      this.previewPopShow = true
      this.previewUrl = imgUrl
    },
    formatDate(date){
      return this.$moment(date).format('YYYY.MM.DD HH:mm')
    },
    initPayLogs() {
      this.payLogs = []
      this.page = 1
      this.noMore = false
      this.loadPayLogs()
    },
    loadPayLogs() {
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      this.$http.post(`/ucenter/account/queryPayLog`, data).then(res => {
        if (res.data && res.data.length > 0) {
          const payLogs = res.data.map(payLog => {
            payLog.amount = payLog.amount / 100
            // payLog.fileId =
            if (payLog.extRemark) {
              // <a className="file-thumb" data-id="3bfee799bb0d4bc2b7b61e05d4d3e5e2" style="color: #136dc9">
              const g = payLog.extRemark.match(/.*data-id="(.*)" style.*/)
              if (g) {
                payLog.fileId = g[1]
              }
              payLog.extRemark = payLog.extRemark.replace(/(<\/?a.*?>)/g, '').replace(/查看图片/g, '')
              if (payLog.extRemark.indexOf('送样')) {
                payLog.extRemark = ''
              }
            }
            return payLog
          })
          that.payLogs = that.payLogs.concat(payLogs)
          that.loading = false
          that.page = that.page + 1
        } else {
          this.noMore = true
        }
      })
    }
  },
  mounted() {
    this.initPayLogs()
  }
}
</script>
